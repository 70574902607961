import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';

import Container from 'common/src/components/UI/ContainerTwo';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Link from 'common/src/components/Link';
import Text from 'common/src/components/Text';
import KonenIcon from './konen-logo.inline.svg';

import FooterWrapper, {
  FooterInner,
  CopyrightInfo,
  FooterWidget,
  Nav,
} from './footer.style';

const Footer = ( { companyLogo, facebookPageUrl, confiablePageUrl, instagramPageUrl }) => {
  const Data = useStaticQuery(graphql`
    query {      
      ConfiableLogo: file(relativePath: { eq: "image/confiable-icon.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 20, maxWidth: 20) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      agencyModernJson {
        aboutUs {
          id
          title
        }
        ourInformation {
          id
          title
        }
        myAccount {
          id
          title
        }
        social {
          id
          title
          icon {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container>
        <FooterInner>
          <CopyrightInfo>
            <Fade up delay={100}>
              <Image src={companyLogo.fluid.src} alt="Logo" />
              <p>
                <Link href="#">Terms of use</Link> |{' '}
                <Link href="#">Privacy</Link>
              </p>
              <Text
                className="copyright"
                content="Derechos Reservados por Konen 360"
              />
            </Fade>
          </CopyrightInfo>
          
          <FooterWidget>
            <Fade up delay={500}>
              <Heading as="h4" content="" />
              </Fade>
         </FooterWidget>
          <FooterWidget>
            <Fade up delay={500}>
              <Heading as="h4" content="Desarollado Por" />
              <KonenIcon style={{ height: "80px", width: "120px" }} />
            </Fade>
          </FooterWidget>
          <FooterWidget>
            <Fade up delay={500}>
              <Heading as="h4" content="Conectar" />
              <Nav>
                {facebookPageUrl && 
                  <Link href={facebookPageUrl} target="_blank">
                  <Image
                    src={Data.agencyModernJson.social.filter(a => a.title == "Facebook")[0].icon.childImageSharp.fluid.src}
                    alt="Facebook"
                  />
                  Facebook
                </Link>
                }

                {confiablePageUrl && 
                  <Link href={confiablePageUrl} target="_blank">                  
                  <Image
                    src={Data.ConfiableLogo.childImageSharp.fluid.src}
                    alt="Confiable"
                  />
                  Confiable
                </Link>
                }
              </Nav>
            </Fade>
          </FooterWidget>
        </FooterInner>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
